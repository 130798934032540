// https://gpt-parser.doclub.tech/api/room/7/

import axios from 'axios'

const $api = axios.create({
	// withCredentials: true,
	baseURL: process.env.VUE_APP_SERVER_API
})

// $api.interceptors.response.use((config) => {
// 	return config
// }, async (error: any) => {
// 	const originalRequest = error.config
// 	console.error(
// 		[
// 			error?.message ?? error?.code,
// 			error?.response?.status
// 		].filter(Boolean).join(' '),
// 		error?.response ?? error
// 	);
// 	if (
// 		error.response?.status === 401 &&
// 		Boolean(error.config) &&
// 		error.response?.request?.responseURL !== process.env.VUE_APP_SERVER_API + '/auth/refresh'
// 	) {
// 		try {
// 			const response = await $api.post('/auth/refresh')

// 			console.log('auth/refresh', response.data)

// 			// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
// 			if (!response.data) throw new Error()

// 			return await $api.request(originalRequest)
// 		} catch (e) {
// 			console.error('Пользователь не авторизован', e)
// 		}
// 	} else {
// 		throw error
// 	}
// })

export {
	$api
}
