import { $api } from "@/shared/api";
import { ref } from "vue";

export const event = ref<string|object|null>('loading')

export async function getEvent(eventId: number) {
    return await $api.get(`/stand/${eventId ? getEvent + '/' : ''}`)
        .then(res => {
            event.value = res?.data;
        })
        .catch(err => {
            console.log(err);
            if (event.value == 'loading') {
                event.value = null;
            }
        })
}